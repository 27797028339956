export interface IFilter {
  sort: {
    direction?: string,
    name?: string | null
  },
  skip: number,
  take: number,

  [key: string]: any,
}

export interface IFiltersState {
  [key: string]:  {
    filter: IFilter
  }
}

export interface IFiltersAction {
  type: string,
  payload: {
    sort: {
      direction?: string,
      name?: string | null
    },
    skip: number,
    take: number,
  }
}

export enum FiltersActionsTypes {
  ACCOUNTS = 'ACCOUNTS_FILTER',
  CLIENTS = 'CLIENTS_FILTER',
  REPORTS = 'REPORTS_FILTER',
  DIALOGUES = 'DIALOGUES_FILTER',
  FEEDBACK_MESSAGES = 'FEEDBACK_MESSAGES_FILTER',
  NEWSLETTERS = 'NEWSLETTERS_FILTER',
  POLL_RESULTS = 'POLL_RESULTS_FILTER',
}