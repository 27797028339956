import {HttpService} from "../../App/services/http"

export const ClientService = {
  client,
  clients,
  filter
}

function client(id: number) {
  return HttpService.get(`/client/${id}`)
    .then(
      response => {
        return response
      })
}

function clients(filter: object) {
  return HttpService.get("/clients", filter)
    .then(
      response => {
        return response
      })
}

function filter(params: object) {
  return HttpService.get("/clients/filter", params)
    .then(
      response => {
        return response
      })
}