import {HttpService} from "../../App/services/http"

export const PollService = {
  result,
  results,
  quest,
  filter
}

function result(id: number) {
  return HttpService.get(`/poll/result/${id}`)
    .then(
      response => {
        return response
      })
}

function results(filter: object) {
  return HttpService.get("/poll/results", filter)
    .then(
      response => {
        return response
      })
}

function quest() {
  return HttpService.get("/poll/quest")
    .then(
      response => {
        return response
      })
}

function filter(params: object) {
  return HttpService.get("/poll/results/filter", params)
    .then(
      response => {
        return response
      })
}