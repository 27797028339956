import React, {Fragment, useState} from 'react'
import {useDispatch} from 'react-redux'

import {Button, Divider as MUIDivider, Grid, IconButton, styled, Typography} from '@mui/material'
import {FilterList, Sort} from "@mui/icons-material";
import {Popover} from "../Popover";
import {Submit} from "../Buttons/Submit";
import {IFilter} from "../../interfaces/filter";

const Item = styled(Grid)(() => ({
  width: "100%",
  padding: "12px 16px"
}))

const Divider = styled(MUIDivider)(() => ({
  width: "100%"
}))

type Props = {
  name: string,
  active: boolean,
  filter: IFilter,
  Content?: any,
  filterActionType: string,
  order?: boolean
}

export const Filter = (props: Props): JSX.Element => {
  const dispatch: any = useDispatch()
  const {name, active, filter, Content, filterActionType, order = true} = props

  const [sort, setSort] = useState({
    name: name,
    direction: (filter.sort.name === name) ? filter.sort.direction : null
  })
  const [filters, setFilters]: any = useState(null)
  const [defaultFilters, setDefaultFilters]: any = useState(null)
  const [anchorEl, setAnchorEl]: any = useState(null)
  const open = Boolean(anchorEl)

  return (
    <Grid>
      <IconButton
        size="small"
        onClick={(event) => {
          setSort({name: name, direction: (filter.sort.name === name) ? filter.sort.direction : null})
          setAnchorEl(event.currentTarget)
        }}
        color={active ? 'primary' : 'default'}
      >
        <FilterList/>
      </IconButton>
      {open ? <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(null)
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Grid container direction="column" alignItems="stretch" justifyContent="space-between">
          {order ? (
            <Fragment>
              <Item>
                <Grid container direction="column" alignItems="stretch" justifyContent="space-between">
                  <Typography fontSize = "12px" color="#A7ADB2">
                    Сортировка
                  </Typography>
                  <Grid item>
                    <Button
                      onClick={() => {
                        const direction = ((sort.direction === 'asc') ? null : 'asc')
                        setSort({...sort, ...{direction: direction}})
                      }}
                      startIcon={<Sort color={(sort.direction === 'asc') ? 'primary' : 'action'} style={{transform: 'rotatex(180deg)'}}/>}
                    >
                      <Typography
                        component="span"
                        variant="body2"
                        color={(sort.direction === 'asc') ? 'primary' : 'black'}
                        style={{textTransform: "none", fontWeight: "400", fontSize: "14px", lineHeight: "20px"}}
                      >
                        По возрастанию (А-Я)
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={() => {
                        const direction = ((sort.direction === 'desc') ? null : 'desc')
                        setSort({...sort, ...{direction: direction}})
                      }}
                      startIcon={<Sort color={(sort.direction === 'desc') ? 'primary' : 'action'}/>}
                    >
                      <Typography
                        component="span"
                        variant="body2"
                        color={(sort.direction === 'desc') ? 'primary' : 'black'}
                        style={{textTransform: "none", fontWeight: "400", fontSize: "14px", lineHeight: "20px"}}
                      >
                        По убыванию (Я-А)
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Item>
              <Divider />
            </Fragment>
          ) : null}
          {Content ? (
            <Fragment>
              <Item>
                <Content
                  filters={filter}
                  setFilters={setFilters}
                  setDefaultFilters={setDefaultFilters}
                />
              </Item>
              <Divider />
            </Fragment>
          ) : null}
          {(order || Content) ? (
            <Item>
              <Grid container direction="row" alignItems="center" justifyContent="space-between">
                <Submit
                  size="small"
                  type="button"
                  variant="outlined"
                  style={{color: "black", background: "#F9FAFA", borderColor: "#DBDEDF"}}
                  onClick={() => {
                    setSort({...sort, ...{direction: null}})
                    dispatch({
                      type: filterActionType,
                      payload: {
                        ...defaultFilters, ...((filter.sort.name === name) ? {
                          sort: {
                            name: null,
                            direction: null,
                          }
                        } : {sort: filter.sort})
                      }
                    })
                  }}
                >
                  Сбросить
                </Submit>
                <Submit
                  size="small"
                  type="button"
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    dispatch({
                      type: filterActionType,
                      payload: {...filters, ...{sort: sort.direction ? sort : filter.sort}}
                    })
                    setAnchorEl(null)
                  }}
                >
                  Применить
                </Submit>
              </Grid>
            </Item>
          ) : null}
        </Grid>
      </Popover> : null}
    </Grid>
  )
}
