import {useParams} from "react-router";
import Accounts from "../Account/Accounts";
import Clients from "../Client/Clients";
import Report from "../Report/Report";

export default function Administration(): JSX.Element | null {
  const params = useParams();

  const getPage = (): JSX.Element | null => {
    switch (params?.section) {
      case 'users':
        return <Accounts />;
      case 'clients':
        return <Clients />;
      case 'reports':
        return <Report />;
      default:
        return null;
    }
  }

  return getPage();
}