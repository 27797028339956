export interface IAccount {
  id: number,
  login: string,
  blocked?: boolean,
  name: string,
  roles?: Array<{ id: number, key: string, name: string, permissions?: Array<{ id: number, key: string, name: string }> }>,
  permissions?: Array<{ id: number, key: string, name: string }>,
  created: Date,
  updated: Date,
}

export interface IAccountState {
  account: IAccount | null
}

export enum AccountActionsTypes {
  FETCH_ACCOUNT = 'FETCH_ACCOUNT'
}