import React, {useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import {Table} from "../App/components/Table";
import {TableHeader} from "../App/components/Table/TableHeader";
import {useDispatch, useSelector} from "react-redux";
import {NewsletterActions} from "./actions/newsletter";
import {IRootState} from "../App/reducers/store";
import {Table as MUITable} from "@devexpress/dx-react-grid-material-ui";
import {FiltersActionsTypes} from "../App/interfaces/filter";
import {INewsletter} from "./interfaces/newsletter";
import {Search} from "@mui/icons-material";
import {Grid,} from "@mui/material";
import TextField from "../App/components/Input/TextField";
import Title from "../App/components/Table/Title";
import {useDebounce} from "use-debounce";
import {TableRow} from "@devexpress/dx-react-grid";
import Create from "./components/Newsletter/Buttons/Create";

const PREFIX = 'Dialogues'

const classes = {
  content: `${PREFIX}-content`,
  inputContent: `${PREFIX}-inputContent`,
  tableRow: `${PREFIX}-tableRow`
}

const Content = styled(Grid)(() => ({
  height: "100%",
  padding: "1rem",
  overflow: "hidden",
  position: "relative",
  [`& .${classes.inputContent}`]: {
    width: "100%",
    height: 'calc(100vh - 163px)',
    minHeight: 'calc(100vh - 163px)',
    overflow: "overlay",
    overflowX: "hidden",
    background: 'white',
  },
  [`& .${classes.tableRow}`]: {
    cursor: "pointer",
    textDecoration: "none"
  }
}))

export function Newsletter(): JSX.Element | null {
  const dispatch: any = useDispatch()
  const {filter} = useSelector((state: IRootState) => state.filters.newsletters)
  const [newsletters, setNewsletters] = useState<{data: Array<INewsletter>, meta?: { total?: number | null }}>({data: [], meta: { total: null }})
  const [page, setPage] = useState(1)
  const [hiddenColumnNames, setHiddenColumnNames] = useState([]);
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState<string | null>(null)
  const [search] = useDebounce(value, 900);

  const columns = [
    {name: 'user', title: 'Пользователь'},
    {name: 'created', title: 'Дата и время рассылки'},
    {name: 'status', title: 'Статус'},
    {name: 'message', title: 'Текст'}
  ]

  const [columnOrder, setColumnOrder] = useState([
    'user',
    'created',
    'status',
    'message'
  ])

  const [columnWidths, setColumnWidths] = useState([
    {columnName: 'user', width: 250},
    {columnName: 'created', width: 250},
    {columnName: 'status', width: 250},
    {columnName: 'message', width: 900}
  ])

  useEffect(() => {
    if (!loading) {
      const skip = (page - 1) * filter.take
      dispatch(NewsletterActions.newsletters({
        ...(filter.sort.name ? {
          sort: filter.sort.name,
          direction: filter.sort.direction,
        } : {}),
        take: filter.take,
        ...(skip ? {skip: skip} : {}),
        ...(!newsletters.meta?.total ? {meta: true} : {}),
        ...(filter.roles?.length ? {roles: filter.roles.join(',')} : {}),
        ...(search?.length ? {search: search} : {}),
      })).then((response: {
        data: [INewsletter],
        meta?: {
          total?: number
        },
      }) => {
        setNewsletters({
          data: response.data,
          meta: response.meta ?? newsletters.meta
        })
        setLoading(true)
      })
    }
    // eslint-disable-next-line
  }, [loading])

  useEffect(() => {
    if (loading) {
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [page])

  useEffect(() => {
    if (loading) {
      setPage(1)
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [filter, search])

  const handlePageSizeChange = (newRowsPerPage: number) => {
    dispatch({
      type: FiltersActionsTypes.NEWSLETTERS,
      payload: {
        ...filter,
        take: newRowsPerPage,
      }
    })
  }

  return loading ? (
    <Content>
      <Title
        title={"Рассылка"}
        actions={[
          <TextField
            type="text"
            value={value}
            placeholder="Поиск"
            icon={<Search />}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const value = event.target.value
              setValue(value.length ? value : '')
            }}
          />,
          <Create
            onClick={(newsletter) => {
              setNewsletters({
                data: [
                  newsletter,
                  ...((newsletters.data.length === filter.take) ? newsletters.data.slice(0, filter.take) : newsletters.data)
                ],
                meta: {
                  total: (newsletters.meta?.total ?? 0) + 1
                },
              })
            }}
          />
        ]}
      />
      <Table
        meta={newsletters.meta}
        name={'newsletters'}
        rows={newsletters.data.map((item: INewsletter) => ({
          user: item.user.name,
          created: new Date(item.created).toLocaleString(),
          status: item.isCompleted ? 'Отправлено' : 'В работе',
          message: item.message,
        }))}
        columns={columns}
        page={{
          page: page,
          setPage: setPage,
          rowsPerPage: filter.take,
          handlePageSizeChange: handlePageSizeChange
        }}
        columnsSettings={{
          columnOrder: columnOrder,
          setColumnOrder: setColumnOrder,
          setColumnWidths: setColumnWidths,
          columnWidths: columnWidths,
          hiddenColumnNames: hiddenColumnNames,
          setHiddenColumnNames: setHiddenColumnNames
        }}
        tableHeader={TableHeader}
        filterActionType={FiltersActionsTypes.NEWSLETTERS}
        getValues={NewsletterActions.filter}
        classInputContent={classes.inputContent}
        rowComponent={({row, tableRow, children}: { row: object, tableRow: TableRow, children: JSX.Element | null }) => (
          <MUITable.Row
            tableRow={tableRow}
            className={classes.tableRow}
            row={row}
            children={children}
            style={{'cursor': 'pointer'}}
          />
        )}
        filters={{
          actions: null,
        }}
      />
    </Content>
  ) : null
}