import {useEffect, useState} from 'react';
import {io, Socket} from 'socket.io-client'
import {useSelector} from "react-redux";
import {IRootState} from "../../App/reducers/store";
import {IAccount} from "../../Account/interfaces/account";
import {IDialogue} from "../interfaces/dialogue";

interface ServerToClientEvents {
  connection: () => void;
  disconnect: () => void;
  operator: (dialogue: number, account?: IAccount) => void;
  message: (message: object) => void;
  fresh: (dialogue: number) => void;
  leave: (dialogue: number) => void;
  create: (dialogue: IDialogue) => void;
}

interface ClientToServerEvents {
  connection: (account: IAccount) => void;
  operators: (dialogues: Array<number>) => void;
  dialogue: (account: IAccount, dialogue: number) => void;
  leave: (params: {dialogue: number, user: 'operator'}) => void;
  close: (params: {dialogue: number}) => void;
}

const socket: Socket<ServerToClientEvents, ClientToServerEvents> = io(process.env.REACT_APP_DIALOGUE_SOCKET_HOST as string,{path: process.env.REACT_APP_DIALOGUE_SOCKET_URL as string});

export const useDialogue = (): [boolean, Socket] => {
  const {account} = useSelector((state: IRootState) => state.account)
  const [connect, setConnect] = useState(false)

  useEffect(() => {
    if (!connect) {
      if (account) {
        socket.emit('connection', account);

        socket.on('connection', () => {
          setConnect(true)
        })

        socket.on('disconnect', () => {
          setConnect(false)
          socket.removeAllListeners()
        })
      }
    }
  }, [connect, account]);

  return [
    connect,
    socket
  ];
}