import {HttpService} from "../../App/services/http"

export const DialogueService = {
  dialogue,
  dialogues,
  filter
}

function dialogue(id: number) {
  return HttpService.get(`/dialogue/${id}`)
    .then(
      response => {
        return response
      })
}

function dialogues(filter: object) {
  return HttpService.get("/dialogues", filter)
    .then(
      response => {
        return response
      })
}

function filter(params: object) {
  return HttpService.get("/dialogues/filter", params)
    .then(
      response => {
        return response
      })
}