import React, {useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import {Table} from "../App/components/Table";
import {TableHeader} from "../App/components/Table/TableHeader";
import {useDispatch, useSelector} from "react-redux";
import {ReportActions} from "./actions/report";
import {IRootState} from "../App/reducers/store";
import {Table as MUITable} from "@devexpress/dx-react-grid-material-ui";
import {FiltersActionsTypes} from "../App/interfaces/filter";
import {Grid,} from "@mui/material";
import Title from "../App/components/Table/Title";
import {TableRow} from "@devexpress/dx-react-grid";
import {IReport} from "./interfaces/report";
import {DatePicker as MUIDatePicker, LocalizationProvider, ruRU} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const PREFIX = 'Dialogues'

const classes = {
  content: `${PREFIX}-content`,
  inputContent: `${PREFIX}-inputContent`,
  tableRow: `${PREFIX}-tableRow`
}

const Content = styled(Grid)(() => ({
  height: "100%",
  padding: "1rem",
  overflow: "hidden",
  position: "relative",
  [`& .${classes.inputContent}`]: {
    width: "100%",
    height: 'calc(100vh - 163px)',
    minHeight: 'calc(100vh - 163px)',
    overflow: "overlay",
    overflowX: "hidden",
    background: 'white',
  },
  [`& .${classes.tableRow}`]: {
    cursor: "pointer",
    textDecoration: "none"
  }
}))

const DatePicker = styled(MUIDatePicker)(({ theme }) => ({
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
    borderColor: 'transparent'
  },
  '& .MuiInputBase-input': {
    borderRadius: "2px",
    position: 'relative',
    backgroundColor: "white",
    border: '1px solid',
    borderColor: "#E5E5E5",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    marginRight: "3px",
    marginLeft: "3px",
    paddingLeft: "3px",
    paddingRight: "3px",
    padding: `${theme.spacing(0.5)} ${theme.spacing(1.5)}`,
  },
}));

export default function Report(): JSX.Element | null {
  const dispatch: any = useDispatch()
  const {filter} = useSelector((state: IRootState) => state.filters.reports)
  const [items, setItems] = useState<{data: Array<IReport>, meta?: { total?: number | null }}>({data: [], meta: { total: null }})
  const [page, setPage] = useState(1)
  const [hiddenColumnNames, setHiddenColumnNames] = useState([]);
  const [loading, setLoading] = useState(false)

  const columns = [
    {name: 'operator', title: 'Оператор'},
    {name: 'dialogues', title: 'Количество диалогов'},
  ]

  const [columnOrder, setColumnOrder] = useState([
    'operator',
    'dialogues',
  ])

  const [columnWidths, setColumnWidths] = useState([
    {columnName: 'operator', width: 350},
    {columnName: 'dialogues', width: 250}
  ])

  useEffect(() => {
    if (!loading) {
      const skip = (page - 1) * filter.take
      dispatch(ReportActions.statistics({
        ...(filter.sort.name ? {
          sort: filter.sort.name,
          direction: filter.sort.direction,
        } : {}),
        ...(filter.filters ? Object.entries(filter.filters).reduce((obj, [name, values]) => {
          return {
            ...obj,
            ...(values ? {[name]: values} : {})
          }
        }, {}) : {}),
        take: filter.take,
        ...(skip ? {skip: skip} : {}),
      })).then((response: {
        data: [IReport],
        meta?: {
          total?: number
        },
      }) => {
        setItems({
          data: response.data,
          meta: response.meta ?? items.meta
        })
        setLoading(true)
      })
    }
    // eslint-disable-next-line
  }, [loading])

  useEffect(() => {
    if (loading) {
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [page])

  useEffect(() => {
    if (loading) {
      setPage(1)
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [filter])

  const handlePageSizeChange = (newRowsPerPage: number) => {
    dispatch({
      type: FiltersActionsTypes.REPORTS,
      payload: {
        ...filter,
        take: newRowsPerPage,
      }
    })
  }

  return loading ? (
    <Content>
      <Title
        title={"Статистика"}
        actions={[
          <Grid item sx={{ maxWidth: "200px"}}>
            <LocalizationProvider adapterLocale='ru' localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText} dateAdapter={AdapterDayjs}>
              <DatePicker
                value={filter.filters?.from ? dayjs(filter.filters?.from) : null}
                onAccept={(value) => {
                  dispatch({
                    type: FiltersActionsTypes.REPORTS,
                    payload: {
                      filters: {
                        ...filter.filters,
                        from: value ? dayjs(value as dayjs.ConfigType).format('YYYY-MM-DD') : null
                      }
                    }
                  })
                }}
                format="DD.MM.YYYY"
                slotProps={{
                  field: {
                    clearable: true,
                    onClear: () => {
                      dispatch({
                        type: FiltersActionsTypes.REPORTS,
                        payload: {
                          filters: {
                            ...filter.filters,
                            from: null
                          }
                        }
                      })
                    }
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>,
          <Grid item>
            —
          </Grid>,
          <Grid item sx={{ maxWidth: "200px"}}>
            <LocalizationProvider adapterLocale='ru' localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText} dateAdapter={AdapterDayjs}>
              <DatePicker
                value={filter.filters?.to ? dayjs(filter.filters?.to) : null}
                onAccept={(value) => {
                  dispatch({
                    type: FiltersActionsTypes.REPORTS,
                    payload: {
                      filters: {
                        ...filter.filters,
                        to: value ? dayjs(value as dayjs.ConfigType).format('YYYY-MM-DD') : null
                      }
                    }
                  })
                }}
                format="DD.MM.YYYY"
                slotProps={{
                  field: {
                    clearable: true,
                    onClear: () => {
                      dispatch({
                        type: FiltersActionsTypes.REPORTS,
                        payload: {
                          filters: {
                            ...filter.filters,
                            to: null
                          }
                        }
                      })
                    }
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>
        ]}
      />
      <Table
        meta={items.meta}
        name={'reports'}
        rows={items.data.map((item: IReport) => ({
          operator: item.operator?.login,
          dialogues: item.dialogues,
        }))}
        columns={columns}
        page={{
          page: page,
          setPage: setPage,
          rowsPerPage: filter.take,
          handlePageSizeChange: handlePageSizeChange
        }}
        columnsSettings={{
          columnOrder: columnOrder,
          setColumnOrder: setColumnOrder,
          setColumnWidths: setColumnWidths,
          columnWidths: columnWidths,
          hiddenColumnNames: hiddenColumnNames,
          setHiddenColumnNames: setHiddenColumnNames
        }}
        tableHeader={TableHeader}
        filterActionType={FiltersActionsTypes.REPORTS}
        classInputContent={classes.inputContent}
        rowComponent={({row, tableRow, children}: { row: object, tableRow: TableRow, children: JSX.Element | null }) => (
          <MUITable.Row
            tableRow={tableRow}
            className={classes.tableRow}
            row={row}
            children={children}
            style={{'cursor': 'pointer'}}
          />
        )}
        filters={{
          operator: null,
          dialogues: null,
        }}
      />
    </Content>
  ) : null
}