import React from "react";
import {Grid} from "@mui/material";
import Edit from "./Actions/Edit";
import Delete from "./Actions/Delete";

type Props = {
  edit?: {
    button?: JSX.Element,
    disabled?: boolean,
    onClick?: () => void,
  },
  delete?: {
    button?: JSX.Element,
    disabled?: boolean,
    onClick?: () => void,
  },
  markup?: {
    button?: JSX.Element,
    disabled?: boolean,
    onClick?: () => void,
  }
}

export default function Actions(props: Props): JSX.Element {
  return <Grid container justifyContent={"flex-start"} alignItems={"center"}>
    {props.edit ? (
      props.edit.button ?? <Edit disabled={props.edit.disabled} onClick={props.edit.onClick} />
    ) : null}
    {props.delete ? (
      props.delete.button ?? <Delete disabled={props.delete.disabled} onClick={props.delete.onClick} />
    ) : null}
    {props.markup?.button ? (props.markup.button) : null}
  </Grid>
}