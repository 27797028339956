import React, {useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import {Table} from "../App/components/Table";
import {TableHeader} from "../App/components/Table/TableHeader";
import {useDispatch, useSelector} from "react-redux";
import {AccountActions} from "./actions/account";
import {IRootState} from "../App/reducers/store";
import {Table as MUITable} from "@devexpress/dx-react-grid-material-ui";
import {FiltersActionsTypes} from "../App/interfaces/filter";
import Actions from "../App/components/Table/Actions";
import {IAccount} from "./interfaces/account";
import {Search} from "@mui/icons-material";
import {Grid,} from "@mui/material";
import TextField from "../App/components/Input/TextField";
import Title from "../App/components/Table/Title";
import Create from "./components/Account/Buttons/Create";
import Edit from "./components/Account/Buttons/Edit";
import Delete from "./components/Account/Buttons/Delete";
import {useDebounce} from "use-debounce";
import {TableRow} from "@devexpress/dx-react-grid";

const PREFIX = 'Accounts'

const classes = {
  content: `${PREFIX}-content`,
  inputContent: `${PREFIX}-inputContent`,
  tableRow: `${PREFIX}-tableRow`
}

const Content = styled(Grid)(() => ({
  height: "100%",
  padding: "1rem",
  overflow: "hidden",
  position: "relative",
  [`& .${classes.inputContent}`]: {
    width: "100%",
    height: 'calc(100vh - 163px)',
    minHeight: 'calc(100vh - 163px)',
    overflow: "overlay",
    overflowX: "hidden",
    background: 'white',
  },
  [`& .${classes.tableRow}`]: {
    cursor: "pointer",
    textDecoration: "none"
  }
}))

export default function Accounts(): JSX.Element | null {
  const dispatch: any = useDispatch()
  const {filter} = useSelector((state: IRootState) => state.filters.accounts)
  const [accounts, setAccounts] = useState<{data: Array<IAccount>, meta?: { total?: number | null }}>({data: [], meta: { total: null }})
  const [page, setPage] = useState(1)
  const [hiddenColumnNames, setHiddenColumnNames] = useState([]);
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState<string | null>(null)
  const [search] = useDebounce(value, 900);

  const columns = [
    {name: 'login', title: 'Имя пользователя'},
    {name: 'name', title: 'ФИО'},
    {name: 'roles', title: 'Права', filter: 'roles'},
    {name: 'created', title: 'Дата создания'},
    {name: 'actions', title: ' '},
  ]

  const [columnOrder, setColumnOrder] = useState([
    'login',
    'name',
    'roles',
    'created',
    'actions',
  ])

  const [columnWidths, setColumnWidths] = useState([
    {columnName: 'login', width: 300},
    {columnName: 'name', width: 450},
    {columnName: 'roles', width: 450},
    {columnName: 'created', width: 150},
    {columnName: 'actions', width: 100, align: 'right'},
  ])

  useEffect(() => {
    if (!loading) {
      const skip = (page - 1) * filter.take
      dispatch(AccountActions.accounts({
        ...(filter.sort.name ? {
            sort: filter.sort.name,
            direction: filter.sort.direction,
          } : {}),
        take: filter.take,
        ...(skip ? {skip: skip} : {}),
        ...(!accounts.meta?.total ? {meta: true} : {}),
        ...(filter.roles?.length ? {roles: filter.roles.join(',')} : {}),
        ...(search?.length ? {search: search} : {}),
      })).then((response: {
        data: [IAccount],
        meta?: {
          total?: number
        },
      }) => {
        setAccounts({
          data: response.data,
          meta: response.meta ?? accounts.meta
        })
        setLoading(true)
      })
    }
    // eslint-disable-next-line
  }, [loading])

  useEffect(() => {
    if (loading) {
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [page])

  useEffect(() => {
    if (loading) {
      setPage(1)
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [filter, search])

  const handlePageSizeChange = (newRowsPerPage: number) => {
    dispatch({
      type: FiltersActionsTypes.ACCOUNTS,
      payload: {
        ...filter,
        take: newRowsPerPage,
      }
    })
  }

  return loading ? (
    <Content>
        <Title
          title={"Сотрудники"}
          actions={[
            <TextField
              type="text"
              value={value}
              placeholder="Поиск: ФИО"
              icon={<Search />}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value
                setValue(value.length ? value : '')
              }}
            />,
            <Create
              onClick={(account) => {
                setAccounts({
                  data: [
                    account,
                    ...((accounts.data.length === filter.take) ? accounts.data.slice(0, filter.take) : accounts.data)
                  ],
                  meta: {
                    total: (accounts.meta?.total ?? 0) + 1
                  },
                })
              }}
            />
          ]}
        />
        <Table
          meta={accounts.meta}
          name={'accounts'}
          rows={accounts.data.map((item: IAccount) => ({
            login: item.login,
            name: item.name,
            roles: item.roles?.map(role => role.name).join(', '),
            created: new Date(item.created).toLocaleDateString(),
            actions: <Actions
              edit={{
                button: <Edit
                  account={item}
                  onClick={(account) => {
                    setAccounts({
                      ...accounts,
                      ...{data: accounts.data.map((el: IAccount) => (el.id === account.id) ? account : el)}
                    })
                  }}
                />
              }}
              delete={{
                button: <Delete
                  id={item.id}
                  onClick={() => {
                    setAccounts({
                      data: accounts.data.filter((el: IAccount) => el.id !== item.id),
                      meta: {
                        total: (accounts.meta?.total ?? 0) + 1
                      },
                    })
                  }}
                />
              }}
            />,
          }))}
          columns={columns}
          page={{
            page: page,
            setPage: setPage,
            rowsPerPage: filter.take,
            handlePageSizeChange: handlePageSizeChange
          }}
          columnsSettings={{
            columnOrder: columnOrder,
            setColumnOrder: setColumnOrder,
            setColumnWidths: setColumnWidths,
            columnWidths: columnWidths,
            hiddenColumnNames: hiddenColumnNames,
            setHiddenColumnNames: setHiddenColumnNames
          }}
          tableHeader={TableHeader}
          filterActionType={FiltersActionsTypes.ACCOUNTS}
          getValues={AccountActions.filter}
          classInputContent={classes.inputContent}
          rowComponent={({row, tableRow, children}: { row: object, tableRow: TableRow, children: JSX.Element | null }) => (
            <MUITable.Row
              tableRow={tableRow}
              className={classes.tableRow}
              row={row}
              children={children}
              style={{'cursor': 'pointer'}}
            />
          )}
          filters={{
            roles: {
              sort: false,
              name: 'roles',
              type: 'values',
            },
            actions: null,
          }}
        />
    </Content>
  ) : null
}