import React, {useEffect} from "react"
import {Dialog as MUIDialog, DialogActions, DialogTitle, Divider, Grid, IconButton,} from "@mui/material";
import {Close} from '@mui/icons-material';
import {DialogContent} from "../../App/components/Page/DialogContent";
import TextField from "../../App/components/Input/TextField"
import {Submit} from "../../App/components/Buttons/Submit";
import {useDispatch} from "react-redux";
import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import IResponseError from "../../App/interfaces/responseError";
import {AccountActions} from "../actions/account";
import {IAccount} from "../interfaces/account";
import {IAccountInput} from "../interfaces/input/account";
import {FormikValues, useFormik} from "formik";
import * as Yup from "yup";
import {transformData} from "../../App/helpers/transformData";
import {Autocomplete} from "../../App/components/Input/Autocomplete";

const Item = styled(Grid)(() => ({
  height: "80px",
  width: "100%",
}))

const Title = styled(Typography)(() => ({
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '32px'
}))

const Dialog = styled(MUIDialog)(() => ({
  '& .MuiPaper-root': {
    borderRadius: `2px`,
  },
  '& .MuiDialogActions-root': {
    padding: '12px 24px'
  }
}))

export default function Account(props: { account?: IAccount, open: boolean, handleClose: () => void, onClick?: (account: IAccount) => void }): JSX.Element | null {
  const dispatch: any = useDispatch();
  const {account, onClick} = props
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (props.open) {
      setOpen(props.open)
    }
  }, [props.open])


  const handleClose = () => {
    setOpen(false);
    props.handleClose()
  };

  const formik = useFormik({
    initialValues: {
      login: account?.login,
      password: '',
      name: account?.name,
      blocked: account?.blocked ?? false,
      roles: account?.roles ?? [{key: 'user', name: 'Пользователь'}]
    },
    validationSchema: Yup.object().shape({
      login: Yup.string().required('Поле обязательно к заполнению'),
      password: account ? Yup.string().optional() : Yup.string().required('Поле обязательно к заполнению'),
      name: Yup.string().required('Поле обязательно к заполнению'),
      blocked: Yup.mixed(),
      roles: Yup.array()
    }),
    onSubmit: (values: FormikValues, {setErrors}) => {
      const data = transformData(values)
      dispatch(account ? AccountActions.update(account.id, (data as IAccountInput)) : AccountActions.create(data as IAccountInput)).then(
        (account: IAccount) => {
          if (onClick) {
            onClick(account)
          }
          handleClose()
        },
        (error: IResponseError) => setErrors(error.errors)
      )
    }
  });

  return open ? (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth={"xs"}
        open={open}
        onClose={handleClose}
      >
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle>
            <Grid container direction="row" alignItems="center" justifyContent="space-between">
              <Grid item xs={10}>
                <Title noWrap>{account ? 'Редактировать' : 'Создать'} аккаунт</Title>
              </Grid>
              <Grid item>
                <IconButton
                  onClick={handleClose}
                >
                  <Close/>
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <Divider/>
          <DialogContent>
            <Grid container direction="column" alignItems="center" justifyContent="center">
              <Item>
                <TextField
                  required
                  formik={formik}
                  name="login"
                  label="Имя пользователя"
                  type="string"
                />
              </Item>
              <Item>
                <TextField
                  required={!account}
                  formik={formik}
                  name="password"
                  label="Пароль"
                  type="password"
                />
              </Item>
              <Item>
                <TextField
                  required
                  formik={formik}
                  name="name"
                  label="ФИО"
                  type="text"
                />
              </Item>
              <Item>
                <Autocomplete
                  name="roles"
                  label="Роли"
                  options={[
                    {key: 'user', name: 'Пользователь'},
                    {key: 'admin', name: 'Администратор'},
                    {key: 'operator', name: 'Оператор'},
                  ].filter(role => !formik.values.roles.find((value: {key: string, name: string}) => role.key === value.key))}
                  setValue={(name: string, value: [{key: string, name: string}] | []) => {
                    formik.setFieldValue(name, [{key: 'user', name: 'Пользователь'}, ...value.filter(role => role.key !== 'user')])
                  }}
                  value={formik.values.roles}
                  getOptionLabel={(option: { key: string, name: string }) => option?.name ?? option}
                  multiple={true}
                />
              </Item>
            </Grid>
          </DialogContent>
          <Divider/>
          <DialogActions>
            <Grid container direction="row" alignItems="center" justifyContent="end">
              <Grid item>
                <Submit
                  variant="contained"
                  type="submit"
                  disabled={!formik.isValid || !formik.dirty}
                >
                  {account ? 'Сохранить' : 'Создать'}
                </Submit>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  ) : null
}
