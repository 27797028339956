import React, {useEffect, useRef, useState} from "react";
import {Divider, Grid, List, ListItem, ListItemText} from "@mui/material";
import {Socket} from "socket.io-client";
import {useSelector} from "react-redux";
import {IMessage} from "./interfaces/message";
import {IDialogue} from "./interfaces/dialogue";
import {FormikValues, useFormik} from "formik";
import * as Yup from "yup";
import TextField from "../App/components/Input/TextField";
import {Submit} from "../App/components/Buttons/Submit";
import {IRootState} from "../App/reducers/store";
import SpeakerNotesOff from "./components/SpeakerNotesOff";
import isRole from "../Auth/hooks/authorize";

type Props = {
  dialogue: IDialogue
  socket: Socket
}

export default function Dialogue(props: Props): JSX.Element | null {
  const {account} = useSelector((state: IRootState) => state.account)
  const [isAdmin] = isRole(account, 'admin');
  const {dialogue, socket} = props;
  const [message, setMessage] = useState<IMessage>()
  const [messages, setMessages] = useState<Array<IMessage>>([])
  const [loading, setLoading] = useState(false)
  const chatRef = useRef<HTMLDivElement | null>(null)
  const messageRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!loading) {
      socket.emit("dialogue", account, dialogue.id, (messages?: Array<IMessage>) => {
        setMessages(messages ?? [])
        setLoading(true)
      })

      socket.on('message', (message: IMessage) => {
        if (message.dialogue_id === dialogue.id) {
          setMessage(message)
        }
      })
    } else {
      return () => {
        socket.emit("leave", {dialogue: dialogue.id, user: isAdmin ? 'admin' : 'operator'})
      }
    }
  }, [loading]);

  useEffect(() => {
    if (dialogue.deleted) {
      setMessages([...messages, {text: 'Диалог завершен', user: 'system', created: dialogue.deleted,}])
    }
  }, [dialogue]);

  useEffect(() => {
    if (message) {
      setMessages([...messages, message])
    }
  }, [message]);

  const formik = useFormik({
    initialValues: {
      text: '',
    },
    validationSchema: Yup.object().shape({
      text: Yup.string().required('')
    }),
    onSubmit: (values: FormikValues, {resetForm}) => {
      socket.emit('message', {text: values.text, dialogue: dialogue.id, user: {id: account?.id, type: 'operator'}}, (message: IMessage) => {
        setMessages([
          ...messages,
          message
        ])

        resetForm()
      })
    }
  });

  useEffect(() => {
    if (chatRef.current) {
      const current = chatRef.current as HTMLDivElement

      current.scrollTo({
        behavior: 'smooth',
        top: current.scrollHeight - current.clientHeight
      })
    }
  }, [messages.length]);

  const getAlign = (user: string) => {
    switch (user) {
      case 'operator':
        return 'end'
      case 'client':
        return 'start'
      default:
        return 'center'
    }
  }
  console.log(messageRef?.current?.clientHeight)
  return loading ? (
    <Grid container direction="column" alignItems="center" justifyContent="stretch" sx={{width: '600px'}}>
      <Grid
        item
        ref={chatRef}
        sx={{
          overflowY: 'auto',
          height: `calc(100vh - ${messageRef?.current?.clientHeight ? messageRef?.current?.clientHeight + 86 : 117}px)`,
          marginTop: '56px',
          width: '100%'
        }}
      >
        <List>
          {messages.map((message, index) => {
            return <ListItem key={index}>
              <Grid container>
                {(message.user === 'system') ? (
                    <Grid item xs={12}>
                      <ListItemText sx={{textAlign: getAlign(message.user)}} secondary={message.text}/>
                    </Grid>
                ) : (
                  <React.Fragment>
                    <Grid item xs={12}>
                      <ListItemText sx={{textAlign: getAlign(message.user)}} primary={message.text}/>
                    </Grid>
                    <Grid item xs={12}>
                      <ListItemText sx={{textAlign: getAlign(message.user)}} secondary={`${(message.user === 'operator') ? 'Оператор' : 'Клиент'} ${new Date(message.created).toLocaleString()}`}/>
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
            </ListItem>
          })}
        </List>
      </Grid>
      <Divider sx={{ width: '100%' }}/>
      <Grid item sx={{
        width: '100%'
      }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container direction="row" alignItems="center" justifyContent="space-between" sx={{ height: `calc(${messageRef?.current?.clientHeight ? 58 + messageRef?.current?.clientHeight : 89}px - 29px)` }}>
            <Grid item xs={1}>
              <Grid container direction="row" alignItems="center" justifyContent="center">
                <Grid item>
                  <SpeakerNotesOff
                    dialogue={dialogue}
                    socket={socket}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <Grid container direction="row" alignItems="center" justifyContent="center">
                <Grid item xs={11} ref={messageRef}>
                  <TextField
                    multiline
                    maxRows={4}
                    required
                    formik={formik}
                    name="text"
                    type="text"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid container direction="row" alignItems="center" justifyContent="center">
                <Grid item>
                  <Submit
                    variant="contained"
                    type="submit"
                    disabled={!socket.connected || !!dialogue.deleted || !formik.isValid || !formik.dirty || formik.isSubmitting}
                  >
                    Отправить
                  </Submit>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  ) : null
}