import {HttpService} from "../../App/services/http"

export const ReportService = {
  statistics
}

function statistics(filter: object) {
  return HttpService.get("/report/statistics", filter)
    .then(
      response => {
        return response
      })
}