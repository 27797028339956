import {applyMiddleware, combineReducers, createStore} from 'redux';
import thunk, {ThunkDispatch} from "redux-thunk";
import logger from "redux-logger";
import {LoadingReducer} from "./loading";
import {ILoadingState} from "../interfaces/loading";
import {IAccountState} from "../../Account/interfaces/account";
import {AccountReducer} from "../../Account/reducers/account";
import {AlertReducer} from "./alert";
import {IAlertsState} from "../interfaces/alert";
import {IFiltersState} from "../interfaces/filter";
import {FiltersReducer} from "./filter";
import {DialogReducer} from "../../Dialogue/reducers/dialogue";
import {IDialogueState} from "../../Dialogue/interfaces/dialogue";

const rootReducer = combineReducers<IRootState>({
  account: AccountReducer,
  dialogue: DialogReducer,
  loading: LoadingReducer,
  alerts: AlertReducer,
  filters: FiltersReducer
});

export interface IRootState {
  account: IAccountState,
  dialogue: IDialogueState,
  loading: ILoadingState,
  alerts: IAlertsState,
  filters: IFiltersState
}

export type RootState = ReturnType<typeof rootReducer>;
type AppAction = ReturnType<typeof store.dispatch>;

export const store = createStore(
  rootReducer,
  applyMiddleware(thunk, logger),
);

export type Dispatch = ThunkDispatch<RootState, never, AppAction>;