import React, {useEffect} from "react"
import {Dialog as MUIDialog, DialogActions, DialogTitle, Divider, Grid, IconButton,} from "@mui/material";
import {Close} from '@mui/icons-material';
import {DialogContent} from "../../App/components/Page/DialogContent";
import TextField from "../../App/components/Input/TextField"
import {Submit} from "../../App/components/Buttons/Submit";
import {useDispatch} from "react-redux";
import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import IResponseError from "../../App/interfaces/responseError";
import {FormikValues, useFormik} from "formik";
import * as Yup from "yup";
import {transformData} from "../../App/helpers/transformData";
import {INewsletter} from "../interfaces/newsletter";
import {NewsletterActions} from "../actions/newsletter";
import {INewsletterInput} from "../interfaces/input/newsletter";

const Item = styled(Grid)(() => ({
  height: "80px",
  width: "100%",
}))

const Title = styled(Typography)(() => ({
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '32px'
}))

const Dialog = styled(MUIDialog)(() => ({
  '& .MuiPaper-root': {
    borderRadius: `2px`,
  },
  '& .MuiDialogActions-root': {
    padding: '12px 24px'
  }
}))

export default function Newsletter(props: { newsletter?: INewsletter, open: boolean, handleClose: () => void, onClick?: (newsletter: INewsletter) => void }): JSX.Element | null {
  const dispatch: any = useDispatch();
  const {newsletter, onClick} = props
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (props.open) {
      setOpen(props.open)
    }
  }, [props.open])


  const handleClose = () => {
    setOpen(false);
    props.handleClose()
  };

  const formik = useFormik({
    initialValues: {
      message: newsletter?.message,
    },
    validationSchema: Yup.object().shape({
      message: Yup.string().required('Поле обязательно к заполнению')
    }),
    onSubmit: (values: FormikValues, {setErrors}) => {
      const data = transformData(values)
      dispatch(newsletter ? NewsletterActions.update(newsletter.id, (data as INewsletterInput)) : NewsletterActions.create(data as INewsletterInput)).then(
        (newsletter: INewsletter) => {
          if (onClick) {
            onClick(newsletter)
          }
          handleClose()
        },
        (error: IResponseError) => setErrors(error.errors)
      )
    }
  });

  return open ? (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth={"xs"}
        open={open}
        onClose={handleClose}
      >
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle>
            <Grid container direction="row" alignItems="center" justifyContent="space-between">
              <Grid item xs={10}>
                <Title noWrap>{newsletter ? 'Редактировать' : 'Создать'} рассылку</Title>
              </Grid>
              <Grid item>
                <IconButton
                  onClick={handleClose}
                >
                  <Close/>
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <Divider/>
          <DialogContent>
            <Grid container direction="column" alignItems="center" justifyContent="center">
              <Item>
                <TextField
                  required
                  formik={formik}
                  name="message"
                  label="Текст"
                  type="text"
                />
              </Item>
            </Grid>
          </DialogContent>
          <Divider/>
          <DialogActions>
            <Grid container direction="row" alignItems="center" justifyContent="end">
              <Grid item>
                <Submit
                  variant="contained"
                  type="submit"
                  disabled={!formik.isValid || !formik.dirty}
                >
                  {newsletter ? 'Сохранить' : 'Создать'}
                </Submit>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  ) : null
}
