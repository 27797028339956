import React, {useEffect} from "react"
import {Button, Dialog as MUIDialog, DialogTitle, Divider, Grid, IconButton} from "@mui/material";
import {Close} from '@mui/icons-material';
import {DialogContent} from "../App/components/Page/DialogContent";
import {useDispatch} from "react-redux";
import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import {Dispatch} from "../App/reducers/store";
import {IPoll} from "./interfaces/poll";

const Title = styled(Typography)(() => ({
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '32px'
}))

const Dialog = styled(MUIDialog)(() => ({
  '& .MuiPaper-root': {
    borderRadius: `2px`,
  },
  '& .MuiDialogActions-root': {
    padding: '12px 24px'
  }
}))

interface Props {
  item: IPoll
}

export default function Result({item}: Props): JSX.Element {
  const dispatch: Dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(true)
  }, [item])
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <React.Fragment>
      <Button onClick={handleOpen}>Изменить</Button>
      <Dialog
        fullWidth
        maxWidth={"xs"}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          <Grid container direction="row" alignItems="center" justifyContent="space-between">
            <Grid item xs={10}>
              <Title noWrap>{item.name}</Title>
            </Grid>
            <Grid item>
              <IconButton
                onClick={handleClose}
              >
                <Close/>
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider/>
        <DialogContent>
          <Grid container direction="column" alignItems="stretch" justifyContent="center" spacing={2}>
            <Grid item>
              <Grid container direction="column" alignItems="stretch" justifyContent="center" spacing={1}>
                {item.answers.map(item => (
                  <Grid item>
                    {item.question ? (
                      <Typography fontSize="16px">
                        {item.question}
                      </Typography>
                    ) : null}
                    <Typography fontSize="14px">
                      {item.answer}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item>
              {item.result}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  )
}
