import React from "react"
import {Navigate, useRoutes} from "react-router";
import NotFoundPage from "./components/NotFoundPage";
import Login from "../Auth/Login";
import Guest from "../Auth/Guest";
import Account from "../Auth/Account";
import Logout from "../Auth/Logout";
import Layout from "./components/Layout";
import Profile from "../Account/Profile";
import Administration from "../Administration/Administration";
import Dialogues from "../Dialogue/Dialogues";
import {Results as PollResults} from "../Poll/Results";
import {Messages as FeedbackMessages} from "../Feedback/Messages";
import {Newsletter} from "../Newsletter/Newsletter";

export default function Routes(): JSX.Element {
    const routing = useRoutes([
        {
            path: '/',
            element:  <Account roles={'user'}><Layout/></Account>,
            children: [
                {path: '/', element: <Navigate to="/dialogues" />},
                {path: 'logout', element: <Logout/>},
                {path: 'account/:section', element: <Profile/>},
                {path: 'profile', element: <Navigate replace to="/account/profile"/>},
                {path: 'administration/:section', element: <Account roles={'admin'}><Administration/></Account>},
                {path: 'administration', element: <Navigate replace to="/administration/users"/>},
                {path: 'dialogues', element: <Dialogues/>},
                {path: 'polls', element: <PollResults/>},
                {path: 'feedback', element: <FeedbackMessages/>},
                {path: 'newsletter', element: <Newsletter/>},
            ]
        },
        {path: '/login', element: <Guest><Login/></Guest>},
        {path: '*', element: <NotFoundPage/>}
    ]);

    return <React.Fragment>{routing}</React.Fragment>;
}