import React from "react";
import {IconButton, MenuItem} from "@mui/material";
import {Socket} from "socket.io-client";
import {SpeakerNotesOff as SpeakerNotesOffIcon} from "@mui/icons-material";
import {IDialogue} from "../interfaces/dialogue";
import {Menu} from "../../App/components/Menu";

type Props = {
  dialogue: IDialogue
  socket: Socket
}

export default function SpeakerNotesOff(props: Props): JSX.Element | null {
  const { dialogue, socket } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <IconButton
        disabled={!!dialogue.deleted}
        onClick={handleClick}
      >
        <SpeakerNotesOffIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            socket.emit('close', {dialogue: dialogue.id})
            handleClose()
          }}
        >
          Завершить диалог
        </MenuItem>
        <MenuItem onClick={handleClose}>Отмена</MenuItem>
      </Menu>
    </React.Fragment>
  )
}