import {SvgIconTypeMap} from "@mui/material/SvgIcon/SvgIcon";
import {OverridableComponent} from "@mui/material/OverridableComponent";
import {
  AccountCircleOutlined,
  LocationOnOutlined,
  GroupOutlined,
  Person, Assessment, AssessmentOutlined,
} from '@mui/icons-material';

type Props = {
  [key: string]: {
    title: string,
    items: ISideMenuItem[]
  }
}

export interface ISideMenuItem {
  name: string,
  path: string,
  alias?: Array<string>,
  icon?: OverridableComponent<SvgIconTypeMap>,
  items?: ISideMenuItem[]
}

export const menu: Props = {
  '/dialogues': {
    title: 'Диалоги',
    items: [
      {name: 'Диалоги', path: '/', icon: LocationOnOutlined},
    ]
  },
  '/account': {
    title: 'Настройки профиля',
    items: [
      {name: 'Профиль', path: '/profile', icon: Person}
    ]
  },
  '/administration': {
    title: 'Администрирование',
    items: [
      {name: 'Сотрудники', path: '/users', icon: AccountCircleOutlined},
      {name: 'Клиенты', path: '/clients', icon: GroupOutlined},
      {name: 'Отчёты', path: '/reports', icon: AssessmentOutlined},
    ]
  }
}

