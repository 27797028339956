import {HttpService} from "../../App/services/http"

export const FeedbackService = {
  message,
  messages,
  filter
}

function message(id: number) {
  return HttpService.get(`/feedback/message/${id}`)
    .then(
      response => {
        return response
      })
}

function messages(filter: object) {
  return HttpService.get("/feedback/messages", filter)
    .then(
      response => {
        return response
      })
}

function filter(params: object) {
  return HttpService.get("/feedback/messages/filter", params)
    .then(
      response => {
        return response
      })
}