import React, {useEffect, useState} from "react";
import {Badge} from "@mui/material";
import Link from "../../../App/components/Link";
import {useDispatch, useSelector} from "react-redux";
import {IRootState} from "../../../App/reducers/store";
import {useDialogue} from "../../hooks/dialogue";
import {IAccount} from "../../../Account/interfaces/account";
import {DialogueActionsTypes, IDialogue} from "../../interfaces/dialogue";
import {DialogueActions} from "../../actions/dialogue";
import useSound from "use-sound";
const sound = require("../../assets/sound.mp3");

export default function Dialogue(): JSX.Element | null {
  const dispatch: any = useDispatch()
  const {dialogue, dialogues} = useSelector((state: IRootState) => state.dialogue)
  const [connect, socket] = useDialogue();
  const [operator, setOperator] = useState<{ id: number, user?: IAccount }>()
  const [close, setClose] = useState<{ id: number }>()
  const [create, setCreate] = useState<IDialogue>()
  const [fresh, setFresh] = useState<{ id: number }>()
  const [play, {stop}] = useSound(sound,{volume: 0.75})
  const [isPlaying, setIsPlaying] = useState(false)

  useEffect(() => {
      dispatch(DialogueActions.dialogues({
        take: 100,
        meta: true
      })).then((response: {
        data: [IDialogue],
        meta?: {
          total?: number
        },
      }) => {
        dispatch({type: DialogueActionsTypes.FETCH_DIALOGUES, payload: {
          data: response.data,
          meta: response.meta ?? dialogues.meta
        }})

        socket.emit("operators", response.data.map(item => item.id), (operators: Array<{id: number, user?: IAccount}>) => {
          operators.forEach(operator => {
            setOperator(operator)
          })
        })
      })
  }, [])

  useEffect(() => {
    socket.on('operator', (dialogue, operator) => {
      setOperator({
        id: dialogue,
        user: operator
      })
    })

    socket.on('fresh', (dialogue) => {
      setFresh({
        id: dialogue
      })
    })

    socket.on('leave', (id) => {
      setClose({id: id})
    })

    socket.on('create', (dialogue) => {
      setCreate(dialogue)
    })
  }, []);

  useEffect(() => {
    if (operator) {
      dispatch({type: DialogueActionsTypes.FETCH_DIALOGUES, payload: {
          ...dialogues,
          data: dialogues.data.map(dialogue => {
            if (operator.id === dialogue.id) {
              return {
                ...dialogue,
                operator: operator.user,
                viewed: operator.user ? true : dialogue.viewed
              }
            }

            return dialogue
          })
        }})
    }
    // eslint-disable-next-line
  }, [operator])

  useEffect(() => {
    if (close) {
      dispatch({type: DialogueActionsTypes.FETCH_DIALOGUES, payload: {
        ...dialogues,
        data: dialogues.data.filter(dialogue => close.id !== dialogue.id)
      }})

      if (dialogue?.id === close.id) {
        dispatch({type: DialogueActionsTypes.FETCH_DIALOGUE, payload: {
          ...dialogue,
          deleted: new Date()
        }})
      }
    }
    // eslint-disable-next-line
  }, [close])

  useEffect(() => {
    if (create) {
      dispatch({type: DialogueActionsTypes.FETCH_DIALOGUES, payload: {
          ...dialogues,
          data: [
            create,
            ...dialogues.data
          ]
        }})
    }
    // eslint-disable-next-line
  }, [create])

  useEffect(() => {
    if (fresh) {
      const item = dialogues.data.find(dialogue => (fresh.id === dialogue.id) && dialogue.viewed)

      if (item) {
        dispatch({
          type: DialogueActionsTypes.FETCH_DIALOGUES,
          payload: {
            ...dialogues,
            data: [
              {
                ...item,
                viewed: false
              },
              ...dialogues.data.filter(dialogue => dialogue.id !== item.id)
            ]
          }
        })

        setIsPlaying(true)
      }
    }
    // eslint-disable-next-line
  }, [fresh])

  useEffect(() => {
    if (isPlaying) {
      play()
    }
    setIsPlaying(false)
    // eslint-disable-next-line
  }, [isPlaying])

  useEffect(() => {
    return () => {
      stop()
    }
  }, [stop])

  return (
    <Link to={"/dialogues"}>
      <Badge
        badgeContent={dialogues.data.filter(dialogue => !dialogue.deleted && !dialogue.operator).length}
        color={dialogues.data.find(dialogue => !dialogue.deleted && !dialogue.operator && !dialogue.viewed) ? "error" : "info"}
      >
        Диалоги
      </Badge>
    </Link>
  )
}