import {HttpService} from "../../App/services/http"
import {INewsletterInput} from "../interfaces/input/newsletter";

export const NewsletterService = {
  newsletter,
  create,
  update,
  newsletters,
  filter
}

function newsletter(id: number) {
  return HttpService.get(`/newsletter/${id}`)
    .then(
      response => {
        return response
      })
}

function create(data: INewsletterInput) {
  return HttpService.post("/newsletter", data, true)
    .then(
      response => {
        return response
      })
}

function update(id: number, data: INewsletterInput) {
  return HttpService.put(`/newsletter/${id}`, data, true)
    .then(
      response => {
        return response
      })
}

function newsletters(filter: object) {
  return HttpService.get("/newsletters", filter)
    .then(
      response => {
        return response
      })
}

function filter(params: object) {
  return HttpService.get("/newsletters/filter", params)
    .then(
      response => {
        return response
      })
}