import Link from "./Link";
import {styled} from "@mui/material/styles";
import Access from "../../Auth/components/Access";
import {Box, Grid as MUIGrid} from '@mui/material';
import Dialogue from "../../Dialogue/components/Links/Dialogue";

const Grid = styled(MUIGrid)(() => ({
  gap: "20px",
  display: "flex",
  '@media all': {
    minHeight: 56,
    maxHeight: 56,
  },
}));

export function Navbar(): JSX.Element {
  return (
    <Box sx={{margin: 0, flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
      <Grid >
        <Dialogue />
        <Link to={"/polls"}>Опросы</Link>
        <Link to={"/newsletter"}>Рассылка</Link>
        <Link to={"/feedback"}>Обратная связь</Link>
        <Access roles={'admin'}><Link to={"/administration"}>Администрирование</Link></Access>
      </Grid>
    </Box>
  );
}
