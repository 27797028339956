import React, {Fragment, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'

import {
  Checkbox,
  Fade,
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material'
import {Waypoint} from "react-waypoint";
import {Search} from "@mui/icons-material";
import TextField from "../../Input/TextField";
import {useDebounce} from "use-debounce";

const Item = styled(Grid)(() => ({
  'width': '100%'
}))

const Cell = styled(TableCell)(() => ({
  'padding': '0'
}))

const Value = styled(Typography)(() => ({
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "20px"
}))

const HiddenCell = styled(TableCell)(() => ({
  padding: '0',
  borderBottom: 'none'
}))

export const Values = (props: any): JSX.Element => {
  const dispatch = useDispatch()
  const {filters, setFilters, setDefaultFilters, title} = props

  const take = 20;
  const [loading, setLoading]: any = useState(false)
  const [initialize, setInitialize]: any = useState(false)
  const [additive, setAdditive]: any = useState(false)
  const [values, setValues]: any = useState([])
  const [skip, setSkip]: any = useState(0)
  const [total, setTotal]: any = useState(0)
  const [value, setValue] = useState<string | null>(null)
  const [search] = useDebounce(value, 900);
  const [filter, setFilter]: any = useState({[title.filter]:  filters[title.filter] !== undefined ? filters[title.filter] : []});

  useEffect(() => {
    setFilters(filter);
    // eslint-disable-next-line
  }, [filter])

  const getData = async (skip = 0) => {
    setSkip(skip)

    return await dispatch(props.getValues({
      name: title.filter,
      take: take,
      ...(skip ? {skip: skip} : {}),
      ...(!total ? {meta: true} : {}),
      ...(search ? {search: search} : {})
    }))
  }

  useEffect(() => {
    setTotal(0)
    setLoading(false)
    // eslint-disable-next-line
  }, [search])

  useEffect(() => {
    if (!initialize) {
      setDefaultFilters({[title.filter]: []})
      getData(0).then(response => {
        setValues(response.data)
        if (response.meta) {
          setTotal(response.meta.total)
        }
        setLoading(true)
        setInitialize(true)
      })
    }
    // eslint-disable-next-line
  }, [initialize])

  useEffect(() => {
    if (!loading && initialize) {
      getData(0).then(response => {
        setValues(response.data)
        if (response.meta) {
          setTotal(response.meta.total)
        }
        setLoading(true)
      })
    }
    // eslint-disable-next-line
  }, [loading, search, initialize])

  return (
    <Fragment>
      <Grid item>
        <Typography fontSize = "12px" color="#A7ADB2">
          {title.title}
        </Typography>
      </Grid>
      <Item item>
        <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
          <Item item>
            <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
              <Grid item>
                <TextField
                  type="text"
                  placeholder="Поиск..."
                  icon={<Search />}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const value = event.target.value
                    setValue(value.length ? value : null)
                  }}
                />
              </Grid>
            </Grid>
          </Item>
          <Item item>
            <TableContainer style={{height: '250px'}}>
              <Table size="small" stickyHeader>
                {loading ? (
                  <TableBody>
                    {values.map((row: { id: number, name: string }, index: number) => {
                      const isItemSelected = (filter[title.filter].indexOf(row.id) !== -1)

                      return (
                        <Fragment key={index}>
                          <Tooltip TransitionComponent={Fade} title={row.name} placement="bottom-end">
                            <Fragment>
                              <TableRow>
                                <Cell>
                                  <Checkbox
                                    onClick={() => {
                                      const selectedIndex = filter[title.filter].indexOf(row.id)

                                      let select: any[] = []

                                      if (selectedIndex === -1) {
                                        select = select.concat(filter[title.filter], row.id)
                                      } else if (selectedIndex === 0) {
                                        select = select.concat(filter[title.filter].slice(1))
                                      } else if (selectedIndex === filter[title.filter].length - 1) {
                                        select = select.concat(filter[title.filter].slice(0, -1))
                                      } else if (selectedIndex > 0) {
                                        select = select.concat(
                                          filter[title.filter].slice(0, selectedIndex),
                                          filter[title.filter].slice(selectedIndex + 1),
                                        )
                                      }

                                      setFilter({[title.filter]: select})
                                    }}
                                    color="primary"
                                    checked={isItemSelected}
                                  />
                                </Cell>
                                <Cell>
                                  <Value variant="body2" noWrap>{row.name}</Value>
                                </Cell>
                              </TableRow>
                              {((values.length !== total) && (total >= take) && (index === (values.length - (take / 2)))) ? (
                                <TableRow>
                                  <HiddenCell colSpan={2}>
                                    <Waypoint
                                      onEnter={() => {
                                        if (!additive) {
                                          setAdditive(true)
                                          getData(skip + take).then(response => {
                                            setAdditive(false)
                                            setValues([...values, ...response.data])
                                            if (response.meta) {
                                              setTotal(response.meta.total)
                                            }
                                          })
                                        }
                                      }}
                                    />
                                  </HiddenCell>
                                </TableRow>
                              ) : null}
                            </Fragment>
                          </Tooltip>
                        </Fragment>
                      )
                    })}
                  </TableBody>
                ) : null}
              </Table>
            </TableContainer>
          </Item>
        </Grid>
      </Item>
    </Fragment>
  )
}
