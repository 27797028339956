import {styled} from "@mui/material/styles";
import Profile from "./Header/Profile";
import {Navbar} from "./Navbar";
import {AppBar,Container, Grid, Toolbar,} from '@mui/material';
import React from "react";

type Props = {
    path: string
}

const StyledAppBar = styled(AppBar)(() => ({
  position: 'sticky',
  boxShadow: 'none',
  background: 'white',
  borderBottom: "1px solid #e0e0e1",
  '@media all': {
    minHeight: 56,
    maxHeight: 56,
  },
}));

const StyledToolbar = styled(Toolbar)(() => ({
  '@media all': {
    minHeight: 56,
    maxHeight: 56,
  },
}));

export default function Header({path}: Props): JSX.Element {
  return (
    <StyledAppBar position="static" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Container maxWidth={false} sx={{margin: 0}} disableGutters>
        <StyledToolbar >
          <img
            loading="lazy"
            src="/logo.png"
            alt={'БОТ "Психологическая помощь"'}
          />
          <Navbar/>
         <Grid>
           <Profile path={path}/>
         </Grid>
        </StyledToolbar>
      </Container>
    </StyledAppBar>
  );
}
