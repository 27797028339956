import React from 'react'
import classNames from 'clsx'

import {PageSizeSelector} from './PageSizeSelector'
import {Pagination} from './Pagination'
import {PageSelector} from "./PageSelector";
import {styled} from "@mui/material/styles";

const PREFIX = 'Pager'

const classes = {
	pager: `${PREFIX}-pager`,
}

const Root = styled('div')(() => ({
	[`&.${classes.pager}`]: {
		display: 'flex',
		padding: '20px',
		alignItems: 'center',
		backgroundColor: 'white',
		justifyContent: 'flex-end',
		borderTop: '1px solid #e0e0e0'
	},
}))

export const Pager = ({
	currentPage,
	pageSizes,
	totalPages,
	pageSize,
	onCurrentPageChange,
	onPageSizeChange,
	totalCount,
	getMessage,
	className,
	...restProps
}: any
) => {
	return (
		<Root
			className={classNames(classes.pager, className)}
			{...restProps}
		>
			<PageSelector
				totalPages={totalPages}
				totalCount={totalCount}
				currentPage={currentPage}
				onCurrentPageChange={(page: any) => onCurrentPageChange(page)}
			/>
			<Pagination
				pageSize={pageSize}
				totalPages={totalPages}
				totalCount={totalCount}
				getMessage={getMessage}
				currentPage={currentPage}
				onCurrentPageChange={(page: any) => onCurrentPageChange(page)}
			/>
			{!!pageSizes.length && (
				<PageSizeSelector
					pageSize={pageSize}
					pageSizes={pageSizes}
					getMessage={getMessage}
					onPageSizeChange={onPageSizeChange}
				/>
			)}
		</Root>
	)
}
