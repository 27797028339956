import React from "react"
import {
  CustomPaging,
  IntegratedSelection,
  IntegratedSummary,
  PagingState,
  SelectionState,
  SummaryState
} from '@devexpress/dx-react-grid'
import {
  DragDropProvider,
  PagingPanel,
  Table as MUITable,
  TableColumnReordering,
  TableColumnResizing,
  TableColumnVisibility,
  TableFixedColumns,
  TableHeaderRow,
  TableBandHeader,
  TableSelection,
} from "@devexpress/dx-react-grid-material-ui";


import {Grid} from "@mui/material";
import {TableHeader} from "./Table/TableHeader";
import {Grid as GridTable} from "./Table/Grid";
import {Pager} from "./Table/Paging/Pager";
import {onCurrentPageChange} from "../helpers/table";
import {StickyTable} from "./Table/StickyTable";
import {TablePaper} from "./Paper";

export const Table = (props: any) => {
  const {
    meta,
    name,
    rows,
    columns,
    columnBands,
    page = null,
    pager,
    selection,
    totalSummaryItems,
    summaryCalculator,
    tableColumnExtensions,
    columnsSettings,
    filterActionType,
    getValues,
    onSelectionChange,
    classInputContent,
    rowComponentSelection,
    rowComponent,
    filters,
  } = props

  const TableHeaderContent = ({column, children, ...restProps}: any) => {
    return (
      <TableHeader
        column={column}
        table={name}
        getValues={getValues}
        filterActionType={filterActionType}
        {...(filters ? {filters: filters} : null)}
        {...(filters && Object.prototype.hasOwnProperty.call(filters, column.name) ? {button: filters[column.name]?.button} : null)}
        {...(filters && Object.prototype.hasOwnProperty.call(filters, column.name) ? {empty: !filters[column.name]} : false)}
        {...restProps}
      >
        {children}
      </TableHeader>
    )
  };

  const getColumnsNames = (columns: any): any => {
    return columns.map((column: any) => column.name)
  }

  return (
    <TablePaper>
      <Grid item className={classInputContent}>
        <GridTable rows={rows} columns={columns}>
          {page ? (
            <PagingState
              currentPage={page.page}
              onCurrentPageChange={e => onCurrentPageChange(e, page.setPage, selection?.setSelection, selection?.selectionName, page.rowsPerPage)}
              pageSize={page.rowsPerPage}
              onPageSizeChange={page.handlePageSizeChange}
            />
          ) : null}
          {page ? (
            <CustomPaging
              totalCount={meta.total ?? 0}
            />
          ): null}
          {selection &&
              <SelectionState
                  selection={selection.selection}
                  onSelectionChange={onSelectionChange}
              />}
          <DragDropProvider/>
          {totalSummaryItems &&
              <SummaryState
                  totalItems={totalSummaryItems}
              />
          }
          {summaryCalculator &&
              <IntegratedSummary
                  calculator={summaryCalculator}
              />
          }
          <MUITable
            noDataCellComponent={() => {
              return null;
            }}
            tableComponent={StickyTable}
            rowComponent={rowComponent ?? null}
            columnExtensions={tableColumnExtensions ?? null}
          />
          {(columnsSettings.columnOrder && columnsSettings.setColumnOrder)
            ? <TableColumnReordering order={columnsSettings.columnOrder} onOrderChange={columnsSettings.setColumnOrder}/>
            : <TableColumnReordering defaultOrder={getColumnsNames(columns)}/>
          }
          <TableColumnResizing
            columnWidths={columnsSettings.columnWidths}
            onColumnWidthsChange={columnsSettings.setColumnWidths}
          />
          <TableHeaderRow contentComponent={TableHeaderContent}/>
          {(columnsSettings.hiddenColumnNames && columnsSettings.setHiddenColumnNames)
            ? <TableColumnVisibility hiddenColumnNames={columnsSettings.hiddenColumnNames} onHiddenColumnNamesChange={columnsSettings.setHiddenColumnNames}/>
            : <TableColumnVisibility defaultHiddenColumnNames={getColumnsNames(columns)}/>
          }
          {columnBands ? <TableBandHeader columnBands={columnBands} /> : null}
          {selection ? <IntegratedSelection/> : null}
          {selection ? (
              <TableSelection
                  showSelectAll
                  selectByRowClick
                  showSelectionColumn={true}
                  highlightRow
                  rowComponent={rowComponentSelection}
              />
          ) : null}
          {columnsSettings.columnWidths.filter((column: { columnName: string, width: number, align?: string }) => column.align).length ? (
            <TableFixedColumns
              leftColumns={columnsSettings.columnWidths.filter((column: { columnName: string, width: number, align?: string }) => (column.align === 'left')).map((column: { columnName: string, width: number, align?: string }) => column.columnName)}
              rightColumns={columnsSettings.columnWidths.filter((column: { columnName: string, width: number, align?: string }) => (column.align === 'right')).map((column: { columnName: string, width: number, align?: string }) => column.columnName)}
            />
          ) : null}
          {page ? (
            <PagingPanel
              containerComponent={pager ?? Pager}
              messages={{
                showAll: 'Все',
                rowsPerPage: 'Записей на странице: ',
                info: (parameters: { from: number; to: number; count: number; }) => `${parameters.from}-${parameters.to} из ${parameters.count}`
              }}
              pageSizes={[10, 50, 100, 200]}
            />
          ) : null}
        </GridTable>
      </Grid>
    </TablePaper>
  )
}
