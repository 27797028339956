import Actions from "../../App/interfaces/actions";
import {DialogueActionsTypes, IDialogueState} from "../interfaces/dialogue";

const init: IDialogueState = {
  dialogues: {data: [], meta: { total: null }},
  dialogue: null
};

export function DialogReducer(state: IDialogueState = init, action: Actions): IDialogueState {
  switch (action.type) {
    case DialogueActionsTypes.FETCH_DIALOGUE:
      return {
        ...state,
        ...{
          dialogue: action.payload
        }
      };
    case DialogueActionsTypes.FETCH_DIALOGUES:
      return {
        ...state,
        ...{
          dialogues: action.payload
        }
      };
    default:
      return state;
  }
}