import {IAccount} from "../../Account/interfaces/account";

export interface IDialogue {
  id: number,
  clientId: number,
  operator?: IAccount,
  viewed: boolean,
  created: Date,
  updated: Date,
  deleted?: Date,
}

export interface IDialogueState {
  dialogues: {
    data: Array<IDialogue>,
    meta: { total: number | null }
  }
  dialogue: IDialogue | null
}

export enum DialogueActionsTypes {
  FETCH_DIALOGUE = 'FETCH_DIALOGUE',
  FETCH_DIALOGUES = 'FETCH_DIALOGUES'
}