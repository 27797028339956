import React, {useState} from "react";
import {styled} from "@mui/material/styles";
import {Table} from "../App/components/Table";
import {TableHeader} from "../App/components/Table/TableHeader";
import {useDispatch, useSelector} from "react-redux";
import {DialogueActions} from "./actions/dialogue";
import {IRootState} from "../App/reducers/store";
import {Table as MUITable} from "@devexpress/dx-react-grid-material-ui";
import {FiltersActionsTypes} from "../App/interfaces/filter";
import {DialogueActionsTypes, IDialogue} from "./interfaces/dialogue";
import {Badge, Drawer, Grid,} from "@mui/material";
import Title from "../App/components/Table/Title";
import {TableRow} from "@devexpress/dx-react-grid";
import {useDialogue} from "./hooks/dialogue";
import Dialogue from "./Dialogue";
import isRole from "../Auth/hooks/authorize";

const PREFIX = 'Dialogues'

const classes = {
  content: `${PREFIX}-content`,
  inputContent: `${PREFIX}-inputContent`,
  tableRow: `${PREFIX}-tableRow`
}

const Content = styled(Grid)(() => ({
  height: "100%",
  padding: "1rem",
  overflow: "hidden",
  position: "relative",
  [`& .${classes.inputContent}`]: {
    width: "100%",
    height: 'calc(100vh - 163px)',
    minHeight: 'calc(100vh - 163px)',
    overflow: "overlay",
    overflowX: "hidden",
    background: 'white',
  },
  [`& .${classes.tableRow}`]: {
    cursor: "pointer",
    textDecoration: "none"
  }
}))

export default function Dialogues(): JSX.Element | null {
  const dispatch: any = useDispatch()
  const {account} = useSelector((state: IRootState) => state.account)
  const [isAdmin] = isRole(account, 'admin');
  const {dialogue, dialogues} = useSelector((state: IRootState) => state.dialogue)
  const [hiddenColumnNames, setHiddenColumnNames] = useState([]);
  const [connect, socket] = useDialogue();

  const columns = [
    {name: 'actions', title: ' '},
    {name: 'clientId', title: 'ID пользователя'},
    {name: 'operator', title: 'Оператор'},
    {name: 'created', title: 'Дата и время начала диалога'},
  ]

  const [columnOrder, setColumnOrder] = useState([
    'actions',
    'clientId',
    'operator',
    'created',
  ])

  const [columnWidths, setColumnWidths] = useState([
    {columnName: 'actions', width: 50},
    {columnName: 'clientId', width: 250},
    {columnName: 'operator', width: 300},
    {columnName: 'created', width: 300},
  ])

  return (
    <React.Fragment>
      <Content>
        <Title
          title={"Диалоги"}
        />
        <Table
          meta={dialogues.meta}
          name={'dialogues'}
          rows={dialogues.data.map((item: IDialogue) => ({
            actions: <Badge
              badgeContent={!item.operator ? 1 : 0}
              color={item.viewed ? "info" : "error"}
              variant="dot"
            />,
            id: item.id,
            clientId: item.clientId,
            operator: item.operator?.name,
            created: new Date(item.created).toLocaleString(),
            dialogue: {
              ...item,
              operator: item.operator
            }
          }))}
          columns={columns}
          columnsSettings={{
            columnOrder: columnOrder,
            setColumnOrder: setColumnOrder,
            setColumnWidths: setColumnWidths,
            columnWidths: columnWidths,
            hiddenColumnNames: hiddenColumnNames,
            setHiddenColumnNames: setHiddenColumnNames
          }}
          tableHeader={TableHeader}
          filterActionType={FiltersActionsTypes.DIALOGUES}
          getValues={DialogueActions.filter}
          classInputContent={classes.inputContent}
          rowComponent={({row, tableRow, children}: { row: {dialogue: IDialogue}, tableRow: TableRow, children: JSX.Element | null }) => (
            <MUITable.Row
              tableRow={tableRow}
              className={classes.tableRow}
              row={row}
              onDoubleClick={() => {
                if (!row.dialogue.operator || isAdmin) {
                  dispatch({type: DialogueActionsTypes.FETCH_DIALOGUE, payload: row.dialogue})
                }
              }}
              children={children}
              style={{'cursor': 'pointer'}}
            />
          )}
          filters={{
            clientId: null,
            operator: null,
            created: null,
            actions: null,
          }}
        />
      </Content>
      {dialogue ? (
        <Drawer
          anchor="right"
          open={!!dialogue}
          onClose={() => dispatch({type: DialogueActionsTypes.FETCH_DIALOGUE, payload: null})}
        >
          <Dialogue dialogue={dialogue} socket={socket} />
        </Drawer>
      ): null}
    </React.Fragment>
  )
}