import React from "react"
import {Submit} from "../../../../App/components/Buttons/Submit";
import AddIcon from "../../../../App/components/Svg/AddIcon";
import Access from "../../../../Auth/components/Access";
import {INewsletter} from "../../../interfaces/newsletter";
import Newsletter from "../../Newsletter";

type Props = {
  onClick?: (newsletter: INewsletter) => void
}

export default function Create(props: Props): JSX.Element {
  const { onClick } = props

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Access roles={'admin'}>
      <React.Fragment>
        <Submit
          onClick={handleOpen}
          variant="contained"
          type="submit"
          endIcon={<AddIcon width={12} height={12}/>}
        >
          Создать
        </Submit>
        {open ? <Newsletter open={open} handleClose={handleClose} onClick={onClick} /> : null}
      </React.Fragment>
    </Access>
  )
}
