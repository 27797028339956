import {FiltersActionsTypes, IFiltersAction, IFiltersState} from "../interfaces/filter";

const defaultFilter = {
  filter: {
    sort: {
      name: null,
      direction: 'desc'
    },
    skip: 0,
    take: 10,
  }
}

const initialState: IFiltersState = {
  accounts: defaultFilter,
  clients: defaultFilter,
  reports: defaultFilter,
  dialogues: defaultFilter,
  feedbackMessages: defaultFilter,
  newsletters: defaultFilter,
  pollResults: defaultFilter,
}

export const FiltersReducer = (state: IFiltersState = initialState, action: IFiltersAction): IFiltersState => {
  let key = '';

  switch (action.type) {
    case FiltersActionsTypes.ACCOUNTS:
    case FiltersActionsTypes.CLIENTS:
    case FiltersActionsTypes.REPORTS:
    case FiltersActionsTypes.DIALOGUES:
    case FiltersActionsTypes.FEEDBACK_MESSAGES:
    case FiltersActionsTypes.NEWSLETTERS:
    case FiltersActionsTypes.POLL_RESULTS:
      key = action.type.replace('_FILTER', '').toLowerCase();
      if (key.includes('_')) {
        const items = key.split('_');
        key = items[0] + items[1].charAt(0).toUpperCase() + items[1].slice(1);
      }

      return {
        ...state,
        ...{
          [key]: {
            filter: {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              ...state[key].filter,
              ...action.payload
            }
          }
        }
      }
    default:
      return state
  }
}