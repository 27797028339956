import React, {useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import {Table} from "../App/components/Table";
import {TableHeader} from "../App/components/Table/TableHeader";
import {useDispatch, useSelector} from "react-redux";
import {PollActions} from "./actions/poll";
import {IRootState} from "../App/reducers/store";
import {Table as MUITable} from "@devexpress/dx-react-grid-material-ui";
import {FiltersActionsTypes} from "../App/interfaces/filter";
import {IPoll} from "./interfaces/poll";
import {Search} from "@mui/icons-material";
import {Grid, Tooltip,} from "@mui/material";
import TextField from "../App/components/Input/TextField";
import Title from "../App/components/Table/Title";
import {useDebounce} from "use-debounce";
import {TableRow} from "@devexpress/dx-react-grid";
import Result from "./Result";
import Typography from "@mui/material/Typography";

const PREFIX = 'Dialogues'

const classes = {
  content: `${PREFIX}-content`,
  inputContent: `${PREFIX}-inputContent`,
  tableRow: `${PREFIX}-tableRow`
}

const Content = styled(Grid)(() => ({
  height: "100%",
  padding: "1rem",
  overflow: "hidden",
  position: "relative",
  [`& .${classes.inputContent}`]: {
    width: "100%",
    height: 'calc(100vh - 196px)',
    minHeight: 'calc(100vh - 196px)',
    overflow: "overlay",
    overflowX: "hidden",
    background: 'white',
  },
  [`& .${classes.tableRow}`]: {
    cursor: "pointer",
    textDecoration: "none"
  }
}))

const Quest = styled(Grid)(() => ({
  boxShadow: "0px 0px 0px 1px rgb(219 222 223 / 44%)",
  width: "100%",
  gap: "20px",
  padding: "8px 20px",
  display: "grid",
  background: "white",
  alignItems: "center",
  minWidth: "calc(100vw - 272px)"
}))

export function Results(): JSX.Element | null {
  const dispatch: any = useDispatch()
  const {filter} = useSelector((state: IRootState) => state.filters.pollResults)

  const [results, setResults] = useState<{data: Array<IPoll>, meta?: { total?: number | null }}>({data: [], meta: { total: null }})
  const [page, setPage] = useState(1)
  const [hiddenColumnNames, setHiddenColumnNames] = useState([]);
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState<string | null>(null)
  const [search] = useDebounce(value, 900);
  const [poll, setPoll] = useState<IPoll>()
  const [quest, setQuest] = useState({all: 0, unique: 0})

  const columns = [
    {name: 'clientId', title: 'ID пользователя'},
    {name: 'name', title: 'Тест'},
    {name: 'created', title: 'Дата и время прохождения'},
    {name: 'result', title: 'Результат'},
  ]

  const [columnOrder, setColumnOrder] = useState([
    'clientId',
    'name',
    'created',
    'result'
  ])

  const [columnWidths, setColumnWidths] = useState([
    {columnName: 'clientId', width: 200},
    {columnName: 'name', width: 450},
    {columnName: 'created', width: 250},
    {columnName: 'result', width: 500},
  ])

  useEffect(() => {
    dispatch(PollActions.quest()).then((response: {
      all: number,
      unique: number,
    }) => {
      setQuest(response)
    })
  }, [])

  useEffect(() => {
    if (!loading) {
      const skip = (page - 1) * filter.take
      dispatch(PollActions.results({
        ...(filter.sort.name ? {
          sort: filter.sort.name,
          direction: filter.sort.direction,
        } : {}),
        take: filter.take,
        ...(skip ? {skip: skip} : {}),
        ...(!results.meta?.total ? {meta: true} : {}),
        ...(filter.roles?.length ? {roles: filter.roles.join(',')} : {}),
        ...(search?.length ? {search: search} : {}),
      })).then((response: {
        data: [IPoll],
        meta?: {
          total?: number
        },
      }) => {
        setResults({
          data: response.data,
          meta: response.meta ?? results.meta
        })
        setLoading(true)
      })
    }
    // eslint-disable-next-line
  }, [loading])

  useEffect(() => {
    if (loading) {
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [page])

  useEffect(() => {
    if (loading) {
      setPage(1)
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [filter, search])

  const handlePageSizeChange = (newRowsPerPage: number) => {
    dispatch({
      type: FiltersActionsTypes.POLL_RESULTS,
      payload: {
        ...filter,
        take: newRowsPerPage,
      }
    })
  }

  return loading ? (
    <Content>
      <Title
        title={"Результаты опросов"}
        actions={[
          <TextField
            type="text"
            value={value}
            placeholder="Поиск: тест"
            icon={<Search />}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const value = event.target.value
              setValue(value.length ? value : '')
            }}
          />
        ]}
      />
      <Quest>
        <Typography variant="body2">Уникальных прохождений конкурсного квеста&emsp;{quest.unique} / {quest.all}</Typography>
      </Quest>
      <Table
        meta={results.meta}
        name={'pollResults'}
        rows={results.data.map((item: IPoll) => ({
          id: item.id,
          clientId: item.clientId,
          result: item.result.substring(0, item.result.search('\n') > 0 ? item.result.search('\n') : item.result.length),
          name: item.name,
          created: new Date(item.created).toLocaleString(),
          updated: new Date(item.updated).toLocaleString(),
          answers: item.answers
        }))}
        columns={columns}
        page={{
          page: page,
          setPage: setPage,
          rowsPerPage: filter.take,
          handlePageSizeChange: handlePageSizeChange
        }}
        columnsSettings={{
          columnOrder: columnOrder,
          setColumnOrder: setColumnOrder,
          setColumnWidths: setColumnWidths,
          columnWidths: columnWidths,
          hiddenColumnNames: hiddenColumnNames,
          setHiddenColumnNames: setHiddenColumnNames
        }}
        tableHeader={TableHeader}
        filterActionType={FiltersActionsTypes.POLL_RESULTS}
        getValues={PollActions.filter}
        classInputContent={classes.inputContent}
        rowComponent={({row, tableRow, children}: { row: IPoll, tableRow: TableRow, children: JSX.Element | null }) => (
          <MUITable.Row
            tableRow={tableRow}
            className={classes.tableRow}
            row={row}
            children={children}
            onDoubleClick={() => {
              if (row.answers?.length) {
                setPoll(row)
              }
            }}
            style={{'cursor': 'pointer'}}
          />
        )}
        filters={{
          actions: null,
        }}
      />
      {poll ? <Result item={poll} /> : null}
    </Content>
  ) : null
}