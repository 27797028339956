import React, {useState} from "react";
import {Avatar, Divider, IconButton, ListItemIcon, ListItemText, MenuItem, MenuList, Tooltip} from '@mui/material';
import {AccountCircle, Logout, Person} from '@mui/icons-material';
import {useSelector} from "react-redux";
import {IRootState} from "../../reducers/store";
import {Link} from "react-router-dom";
import {Menu} from "../Menu";
import {NavigateFunction, useNavigate} from "react-router";

type Props = {
    path: string
}

export default function Profile({path}: Props): JSX.Element | null {
    const {account} = useSelector((state: IRootState) => state.account)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const navigate: NavigateFunction = useNavigate();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return account ? (
        <React.Fragment>
            <Tooltip title="Профиль">
                <IconButton
                    onClick={handleClick}
                >
                    <Avatar>
                        <AccountCircle/>
                    </Avatar>
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={{ mt: '45px' }}
                keepMounted
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuList dense>
                    <MenuItem disabled={true}>
                        <ListItemText primary={account.name || account.login} />
                    </MenuItem>
                    <Divider />
                    <MenuItem
                        selected={path === '/account/profile'}
                        onClick={() => {
                            handleClose()
                            navigate('/account/profile')
                        }}
                    >
                        <ListItemIcon>
                            <Person fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Профиль</ListItemText>
                    </MenuItem>
                    <Divider />
                    <MenuItem
                        component={Link}
                        to="/logout"
                        onClick={handleClose}
                    >
                        <ListItemIcon>
                            <Logout fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Выход</ListItemText>
                    </MenuItem>
                </MenuList>
            </Menu>
        </React.Fragment>
    ) : null
}
