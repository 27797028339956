function onCurrentPageChange(newPage: number, setPage: any, setSelection: any, selectionData: any, rowsPerPage: number) {
    setPage(newPage)

    if (setSelection && selectionData) {
        setSelection(Object.keys(selectionData).map(key => newPage > 1 ? parseInt(key) - rowsPerPage : parseInt(key)).filter(key => {
            const max = newPage * rowsPerPage
            const min = max - rowsPerPage
            if (newPage > 1) {
                key = key + rowsPerPage
            }
            return key >= min && key < max
        }))
    }
}

function onSelectionChange(numbers: any, data: any, selectionData: any, setSelectionData: any, setSelection: any, rowsPerPage: any, page: any) {
    numbers = Array.from(new Set(numbers))
    const newSelection = selectionData
    const min = (page * rowsPerPage) - rowsPerPage
    data.forEach((el: any, idx: number) => {
        if (numbers.indexOf(idx) !== -1) {
            newSelection[min + idx] = el
        } else {
            delete newSelection[min + idx]
        }
    })
    setSelectionData(newSelection.filter((el: any) => el))
    setSelection(numbers)
}

export {onCurrentPageChange, onSelectionChange}
